define("shared/catalog/service", ["exports", "shared/utils/util", "ui/utils/parse-externalid", "shared/utils/constants"], function (_exports, _util, _parseExternalid, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    globalStore: Ember.inject.service(),
    settings: Ember.inject.service(),
    store: Ember.inject.service(),
    scope: Ember.inject.service(),
    app: Ember.inject.service(),
    catalogs: null,
    _allCatalogs: Ember.computed.union('globalCatalogs', 'clusterCatalogs', 'projectCatalogs'),
    _allTemplates: null,
    globalCatalogs: null,
    clusterCatalogs: null,
    projectCatalogs: null,

    init() {
      this._super(...arguments);

      const store = Ember.get(this, 'globalStore');
      Ember.setProperties(this, {
        globalCatalogs: store.all('catalog'),
        clusterCatalogs: store.all('clustercatalog'),
        projectCatalogs: store.all('projectcatalog'),
        '_allTemplates': store.all('template')
      });
    },

    reset() {
      this.setProperties({
        catalogs: null
      });
    },

    refresh() {
      const store = Ember.get(this, 'globalStore');
      return this.fetchUnScopedCatalogs().then(() => {
        return Ember.RSVP.hash({
          projectCatalogs: store.request({
            method: 'POST',
            url: `${Ember.get(this, 'app.apiEndpoint')}/projectcatalogs?action=refresh`
          }),
          clusterCatalogs: store.request({
            method: 'POST',
            url: `${Ember.get(this, 'app.apiEndpoint')}/clustercatalogs?action=refresh`
          }),
          globalCatalogs: store.request({
            method: 'POST',
            url: `${Ember.get(this, 'app.apiEndpoint')}/catalogs?action=refresh`
          })
        });
      });
    },

    fetchAppTemplates(apps) {
      let deps = [];
      apps.forEach(app => {
        let extInfo = (0, _parseExternalid.parseHelmExternalId)(app.get('externalId'));

        if (extInfo && extInfo.templateId) {
          deps.push(this.fetchTemplate(extInfo.templateId, false));
        }
      });
      return Ember.RSVP.allSettled(deps);
    },

    fetchMultiClusterAppTemplates(apps) {
      let deps = [];
      apps.forEach(app => {
        let extInfo = Ember.get(app, 'externalIdInfo');

        if (extInfo && extInfo.templateId) {
          deps.push(this.fetchTemplate(extInfo.templateId, false));
        }
      });
      return Ember.RSVP.allSettled(deps);
    },

    fetchUnScopedCatalogs() {
      return Ember.RSVP.hash({
        projectCatalogs: this.fetchCatalogs('projectCatalog'),
        clusterCatalogs: this.fetchCatalogs('clusterCatalog'),
        globalCatalogs: this.fetchCatalogs('catalog')
      });
    },

    fetchCatalogs(catalogs = 'catalog', opts) {
      return Ember.get(this, 'globalStore').findAll(catalogs, opts);
    },

    getTemplateFromCache(id) {
      return Ember.get(this, 'globalStore').getById('template', id);
    },

    getVersionFromCache(id) {
      return Ember.get(this, 'globalStore').getById('templateversion', id);
    },

    fetchTemplate(id, upgrade = false) {
      let type, cached, params;

      if (upgrade === true) {
        type = 'templateversions';
        cached = this.getVersionFromCache(id);
      } else {
        type = 'templates'; // we cant check for cached here anylonger because the clusterName is used to build a dynamic versions list of compatible versions for this clusters kube version

        params = {
          clusterName: Ember.get(this, 'scope.currentCluster.id')
        };
      }

      if (cached) {
        return Ember.RSVP.resolve(cached);
      }

      let url = this._addLimits(`${Ember.get(this, 'app.apiEndpoint')}/${type}/${id}`, params);

      return Ember.get(this, 'globalStore').request({
        url
      });
    },

    fetchTemplates() {
      if (arguments.length) {
        console.error('Deprecated', new Error("Use a catalogService.filter(globalStore.all('templates'))"));
      }

      const globalStore = Ember.get(this, 'globalStore');
      const qp = {
        'category_ne': 'system'
      };

      const url = this._addLimits(`${Ember.get(this, 'app.apiEndpoint')}/templates`, qp);

      return globalStore.request({
        url
      }).then(() => ({
        catalog: this._allTemplates
      }));
    },

    cleanVersionsArray(template) {
      return Object.keys(template.versionLinks).filter(key => {
        // Filter out empty values for rancher/rancher#5494
        return !!template.versionLinks[key];
      }).map(key => {
        return {
          version: key,
          sortVersion: key,
          link: template.versionLinks[key]
        };
      });
    },

    fetchByUrl(url) {
      return Ember.get(this, 'store').request({
        url
      });
    },

    filter(data, project, istio) {
      let currentProjectId = project ? project.id : null;
      let currentClusterId = project ? project.clusterId : null;
      data = data.filter(tpl => {
        if (tpl.clusterId && tpl.clusterId !== currentClusterId) {
          return false;
        }

        if (tpl.projectId && tpl.projectId !== currentProjectId) {
          return false;
        }

        if (typeof istio !== undefined) {
          if (istio !== Ember.get(tpl, 'isIstio')) {
            return false;
          }

          if (!istio && Ember.get(tpl, 'catalogId') === _constants.default.CATALOG.SYSTEM_LIBRARY_KEY) {
            return false;
          }
        }

        return true;
      });
      data = data.sortBy('name');
      return data;
    },

    uniqueCategories(data) {
      let out = [];
      data.forEach(obj => {
        out.pushObjects(obj.get('categoryArray'));
      });
      out = (0, _util.uniqKeys)(out);
      out.unshift('');
      return out;
    },

    _addLimits(url, qp) {
      qp = qp || {};
      url = (0, _util.addQueryParams)(url, qp);
      return url;
    }

  });

  _exports.default = _default;
});